import Footer from "../components/Footer"
import Navbar from "../components/Navbar"

const Paymentfailure=()=>{
    return(
        <>
        <Navbar/>
        <div>Hey this is payment failure</div>
        <Footer/>
        </>
    )
}
export default Paymentfailure