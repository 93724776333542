import React from "react";
import logo from "../assets/logo.png"
import { GoDash } from "react-icons/go";
import { CiMail } from "react-icons/ci";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaYoutubeSquare } from "react-icons/fa";
import footer from "../assets/foot.png"
import { Link } from "react-router-dom";
const Footer=()=>{
    return(
        <div className="lg:flex justify-between bg-white overflow-hidden">
            <div className="lg:px-10 lg:w-2/5 py-10">
                <img src={logo} className="w-1/2 mt-5 mb-5 lg:ml-5 mx-auto"></img>
                <div className="sm:flex lg:ml-5 mx-2">
                <div className="sm:w-1/2 lg:w-full">
                    <div className=" font-semibold lg:text-left text-center">Our Mission</div>
                    <div className="text-sm lg:mr-5 lg:text-left text-center">Help Indians to compare offline medicine prices and buy at best price within their reach.</div>
                </div>
                <div className="sm:w-1/2 lg:w-full mt-5 sm:mt-0">
                    <h1 className=" font-semibold lg:ml-5 lg:text-left text-center">Our Vision</h1>
                    <div className="text-sm lg:ml-5 lg:text-left text-center">Help Indian community with medicine prices which is affordable, trusted and within their reach.</div>
                </div>
                </div>
            </div>
            <div className="relative flex lg:w-4/5 bg-gradient-to-r from-orange-400 to-yellow-400 lg:bg-none">
                <img src={footer} className="absolute w-full h-full z-10 lg:block hidden"></img>
                <div className="w-2/12 lg:block hidden"></div>
                <div className="z-20 sm:flex justify-between lg:w-10/12 sm:py-10 w-full lg:mx-0 mx-5">
                <div className="sm:w-1/3">
                    <div className="font-semibold mb-5 text-center lg:text-left">Important Links</div>
                    <ul className="w-max mx-auto lg:w-full">
                        <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/about">About Us</Link></li>
                        <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/faq">FAQ's</Link></li>
                        <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/testimonials">Testimonials</Link></li>
                        <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/terms">Terms & Conditions</Link></li>
                        <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/cyber-security">Cyber Security</Link></li>
                        <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/ip-policy">IP Policy</Link></li>
                        <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="https://smart-pills-admin.web.app/">Partner Login</Link></li>
                        <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/privacy">Privacy Policy</Link></li>
                    </ul>
                </div>

                <div className=" sm:w-1/3">
                <div className="font-semibold mb-5 text-center lg:text-left">Queries</div>
                <ul className="w-max mx-auto lg:w-full">
                <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/query?label=Cancer">Cancer</Link></li>

                <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/query?label=Chronic%20Care">Chronic Care</Link></li>

                <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/query?label=Generics">Generics</Link></li>

                <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/query?label=Special%20Needs">Special Needs</Link></li>

                <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/alliance">Alliances</Link></li>

                <li className="flex text-sm mb-3"><GoDash className="my-auto"></GoDash><Link to="/smartchamp">Smart Champ</Link></li>

                </ul>
                </div>


                <div className="sm:w-1/3">
                    <div className=" font-semibold mb-5 text-center lg:text-left">Reach Us</div>
                    <div className="flex w-max mx-auto lg:w-full">
                        <CiMail className="my-auto mr-3" fontSize="1.5rem"></CiMail>
                        <div className="text-sm"><Link to="mailto: info@smartpills.in">info@smartpills.in</Link></div>
                    </div>
                    <div className=" mt-8 mb-5 font-semibold w-max mx-auto lg:w-full">Follow SmartPills on</div>
                    <div className="flex w-max mx-auto lg:w-full mb-5 sm:mb-0">
                        <Link to="https://www.facebook.com/smartpillsindia"><FaFacebook className="mr-3" fontSize="1.2rem"></FaFacebook></Link>
                        <Link to="https://www.instagram.com/smartpillsindia/?igshid=MzRlODBiNWFlZA%3D%3D"><FaInstagramSquare className="mr-3" fontSize="1.2rem"></FaInstagramSquare></Link>
                        <Link to="https://www.linkedin.com/company/smartpillsindia/"><FaLinkedin className="mr-3" fontSize="1.2rem"></FaLinkedin></Link>
                        <Link to="https://twitter.com/SmartPillsIndia"><FaSquareXTwitter className="mr-3" fontSize="1.2rem"></FaSquareXTwitter></Link>
                        <Link to="https://www.youtube.com/@Smart-Pills"><FaYoutubeSquare className="" fontSize="1.2rem"></FaYoutubeSquare></Link>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;