import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Article from "../components/Article";
import img from "../assets/article.png"
const Articles =()=>{
    return(
        <>
        <Navbar/>
        <div className="mx-10 my-20">
            <div style={{textAlign:'center'}} className="font-semibold text-4xl">Healthcare Useful Articles</div>     
            <div className="my-5 text-gray-500 text-2xl" style={{textAlign:'center'}}>From healthcare providers, pharmacists, and journalists you can trust.</div>       
            <Article title="A new Blood test to detect Alzheimer in advance" bio="New blood test can detect 'toxic' protein years before Alzheimer's symptoms emerge." img={img} link="41284375r2fawe"/>
            <Article title="A new Blood test to detect Alzheimer in advance" bio="New blood test can detect 'toxic' protein years before Alzheimer's symptoms emerge." img={img} link="41284375r2fawe"/>
            <Article title="A new Blood test to detect Alzheimer in advance" bio="New blood test can detect 'toxic' protein years before Alzheimer's symptoms emerge." img={img} link="41284375r2fawe"/>

        </div>
        <Footer/>
        </>
    )
}
export default Articles;