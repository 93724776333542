import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import axiosClient from "../components/AxiosClient"; // Assuming you have an Axios client set up
import { toast } from "react-toastify"; // Assuming you use react-toastify for notifications

const Cart = ({ setCart }) => {
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subtotal, setSubtotal] = useState(0);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axiosClient.post("/cart/get_cart", null, config);

        if (response.status === 200) {
          setCartItems(response.data.cart); // Extract cart items from response data
        } else {
          throw new Error("Failed to fetch cart items");
        }
      } catch (error) {
        console.error("Error fetching cart items:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCartItems();
  }, []);

  useEffect(() => {
    const calculateSubtotal = () => {
      const total = cartItems.reduce((sum, item) => sum + item.mrp, 0);
      setSubtotal(total);
    };

    calculateSubtotal();
  }, [cartItems]);

  const handleRemoveFromCart = async (itemId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const formData = new FormData();
      formData.append("product_main_id", itemId);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axiosClient.post(`/cart/delete_cart`, formData, config);

      if (response.status === 200) {
        setCartItems(cartItems.filter(item => item.product_main_id !== itemId));
        toast.success("Item removed from cart");
      } else {
        throw new Error("Failed to remove item from cart");
      }
    } catch (error) {
      console.error("Error removing item from cart:", error);
      toast.error("Error removing item from cart");
    }
  };
  return (
    <div
      className="absolute top-0 right-0 bg-white px-7 py-7 flex flex-col justify-between"
      style={{ height: "100vh", width: "30%" }}
    >
      <div>
        <div className="flex justify-between my-auto mb-8 ">
          <div style={{ fontSize: "1.1rem" }}>Cart</div>
          <div
            className="flex"
            onClick={() => {
              setCart(false);
              document.body.classList.remove("no-scroll");
            }}
          >
            <IoClose className="my-auto mr-4" />
            <div className="text-sm my-auto">Close</div>
          </div>
        </div>
        <hr />
      </div>
      {isLoading ? (
        <div className="text-sm mt-4 h-full">Loading...</div>
      ) : (
        <div className="text-sm mt-4 h-full">
          {cartItems.length === 0 ? (
            <div>No products have been added to your cart yet.</div>
          ) : (
            <div>
              {cartItems.map((item) => (
                <div key={item.id} className="flex justify-between my-4">
                  <div>
                    <p>{item.name}</p>
                    <p>₹ {item.mrp}</p>
                  </div>
                  <button
                    className="text-red-500"
                    onClick={() => handleRemoveFromCart(item.product_main_id)}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <hr />
              <div className="flex justify-between my-4">
                <div>Subtotal</div>
                <div>₹ {subtotal}</div>
              </div>
              <hr />
              <button className="bg-gradient-to-r from-orange-400 to-yellow-400 w-full py-3 rounded-lg mt-6 text-white font-semibold">
                CHECKOUT
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Cart;
