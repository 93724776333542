import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axiosClient from '../components/AxiosClient';

const Coupons = () => {
  const [couponsData, setCouponsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterUsed, setFilterUsed] = useState(null);
  const [selectedCoupon, setSelectedCoupon] = useState(null); // State for selected coupon
  const [isLoggedIn, setIsLoggedIn] = useState(true); // State for login status

  useEffect(() => {
    const fetchCoupons = async () => {
      setIsLoading(true);
      const userToken = localStorage.getItem('token');
      if (!userToken) {
        setIsLoggedIn(false);
        setIsLoading(false);
        return;
      }

      try {
        const formData = new FormData();
        formData.append('used', filterUsed !== null ? filterUsed.toString() : 'None');
        formData.append('order', 'ascending');

        const response = await axiosClient.post(
          '/coupons/get_coupons_user',
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        console.log('Coupons retrieved successfully');
        setCouponsData(response.data.data[0]);
        console.log(response.data.data[0])
      } catch (error) {
        console.error('Error fetching coupons:', error);
      }
      setIsLoading(false);
    };

    fetchCoupons();
  }, [filterUsed]);

  const handleFilterUsed = (usedStatus) => {
    setFilterUsed(usedStatus);
  };

  const handleViewDetails = (coupon) => {
    setSelectedCoupon(coupon);
  };

  const handleClosePopup = () => {
    setSelectedCoupon(null);
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto my-8">
        {isLoggedIn ? (
          <>
            <div className="flex justify-between mb-4">
              <button
                onClick={() => handleFilterUsed('True')}
                className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-4 py-2 rounded-md focus:outline-none focus:bg-blue-600 mr-4"
              >
                Used
              </button>
              <button
                onClick={() => handleFilterUsed('False')}
                className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-4 py-2 rounded-md focus:outline-none focus:bg-green-600"
              >
                Not Used
              </button>
            </div>
            <h1 className="text-3xl font-bold my-8">Coupons</h1>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <div>
                <div className="flex justify-between font-semibold border-b border-gray-300 pb-2">
                  <div>Product Name</div>
                  <div>Vendor Phone Number</div>
                  <div>Discount</div>
                  <div>Discounted Price</div>
                  <div>View</div>
                </div>
                {couponsData.map((coupon, index) => (
                  <div key={index} className="border border-gray-200 p-4 my-4">
                    <div className="flex justify-between items-center">
                      <div>{coupon.product_name}</div>
                      <div>{coupon.vendor_phone}</div>
                      <div>{coupon.discount}</div>
                      <div>{coupon.discounted_price}</div>
                      <button onClick={() => handleViewDetails(coupon)}>View</button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : (
          <p className="text-red-500 text-center">Please login to see your coupons.</p>
        )}
      </div>
      {selectedCoupon && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <div className="mb-4">
              <h2 className="text-xl font-semibold">Coupon Details</h2>
            </div>
            <div>
              <p>User Name: {selectedCoupon.user_name}</p>
              <p>Vendor Phone Number: {selectedCoupon.vendor_phone}</p>
              <p>Product Name: {selectedCoupon.product_name}</p>
              <p>Pharacy Name: {selectedCoupon.pharmacy_name}</p>
              <p>Discount: {selectedCoupon.discount}</p>
              <p>Discounted Price: {selectedCoupon.discounted_price}</p>
              <p>Mrp: {selectedCoupon.mrp}</p>
              <p>Created At: {selectedCoupon.created_at}</p>
              <p>Expiry: {selectedCoupon.expiry}</p>
              <p>Is Used: {selectedCoupon.is_used.toString()}</p>
              <p>Quantity: {selectedCoupon.quantity}</p>
            </div>
            <div className="mt-4 text-right">
              <button
                onClick={handleClosePopup}
                className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md focus:outline-none focus:bg-gray-500"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Coupons;
