import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Pricing from "../components/Pricing";
import Drug from "../components/Drug";
import Interaction from "../components/Interaction";
import Images from "../components/Images";
import Uses from "../components/Uses";
import Warnings from "../components/Warnings";
import SideEffects from "../components/Sideeffects";
import axiosClient from "../components/AxiosClient";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Tabletinfo = () => {
  const [product, setProduct] = useState({});
  const [productId, setProductId] = useState("");
  const [pincode, setPincode] = useState("");
  const [token, setToken] = useState("");
  const [prodname, setName] = useState("");
  const [activeSection, setActiveSection] = useState("pricing");
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    const pincodeParam = params.get("pincode");
    const name = decodeURIComponent(params.get("name"));  // Decoding the name parameter
    const storedToken = localStorage.getItem("token");

    setProductId(id);
    setPincode(pincodeParam);
    setToken(storedToken);
    setName(name);

    if (id && pincodeParam && storedToken) {
      getProductInfo(id, pincodeParam, storedToken);
    }
  }, [location.search]);

  const getProductInfo = async (productId, pincode, token) => {
    try {
      const formData = new FormData();
      formData.append("pincode", pincode);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axiosClient.post(
        `/products/get_product/${productId}`,
        formData,
        config
      );

      console.log(response.data.data[0]);
      setProduct(response.data.data[0]);
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized. Please log in again.");
        // Redirect to login or handle token expiration
      } else {
        toast.error("An error occurred while fetching product info.");
      }
    }
  };

  const handleAddToBookmark = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axiosClient.post(
        `/bookmarks/add_bookmark/${productId}`,
        null,
        config
      );

      console.log("Bookmark added:", response.data);
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error adding bookmark:", error);
      toast.error("Error adding bookmark");
    }
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  return (
    <>
      <Navbar />
      <div className="mx-5 pt-10 flex">
        <div className="bg-gray-200" style={{ width: "200px", height: "200px" }}>
          <img src={product.img} className="mx-auto my-auto" alt="Medicine" />
        </div>
        <div className="ml-5">
          <div className="text-3xl font-semibold">{product.name}</div>
          <div>
            {product.medicine_type} • {product.packaging} • {product.salt_composition}
          </div>
          <button className="text-white rounded-md bg-gradient-to-r from-orange-400 to-yellow-400 px-2 py-2 my-2" onClick={handleAddToBookmark}>
            Add to Bookmark
          </button>
          <div className="text-sm">
            {product.product?.description || ""}
          </div>
        </div>
      </div>
      {/* Options section */}
      <div className="bg-gradient-to-r from-orange-400 to-yellow-400 mt-5 flex justify-between text-white py-3 mx-5">
        <button className={`mx-5 ${activeSection === "pricing" ? "font-bold" : ""}`} onClick={() => handleSectionChange("pricing")}>
          Pricing
        </button>
        <button className={`mx-5 ${activeSection === "drug" ? "font-bold" : ""}`} onClick={() => handleSectionChange("drug")}>
          Drug Info
        </button>
        <button className={`mx-5 ${activeSection === "interaction" ? "font-bold" : ""}`} onClick={() => handleSectionChange("interaction")}>
          Interaction
        </button>
        <button className={`mx-5 ${activeSection === "images" ? "font-bold" : ""}`} onClick={() => handleSectionChange("images")}>
          Images
        </button>
        <button className={`mx-5 ${activeSection === "uses" ? "font-bold" : ""}`} onClick={() => handleSectionChange("uses")}>
          Uses
        </button>
        <button className={`mx-5 ${activeSection === "warnings" ? "font-bold" : ""}`} onClick={() => handleSectionChange("warnings")}>
          Warnings
        </button>
        <button className={`mx-5 ${activeSection === "sideEffects" ? "font-bold" : ""}`} onClick={() => handleSectionChange("sideEffects")}>
          Side Effects
        </button>
      </div>
      {/* Render content based on active section */}
      {activeSection === "pricing" && <Pricing product={product} productId={productId} pincode={pincode} token={token} />}
      {activeSection === "drug" && <Drug product={product} />}
      {activeSection === "interaction" && <Interaction product={product} />}
      {activeSection === "images" && <Images product={product} />}
      {activeSection === "uses" && <Uses product={product} />}
      {activeSection === "warnings" && <Warnings product={product} />}
      {activeSection === "sideEffects" && <SideEffects product={product} />}
      <Footer />
      <ToastContainer />
    </>
  );
};

export default Tabletinfo;
