import React from "react";

const Images = ({ product }) => {
    return (
        <div>
            {product.image ? (
                <img src={product.image} alt="Product" />
            ) : (
                <p>No images found</p>
            )}
        </div>
    );
};

export default Images;
