import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axiosClient from "../components/AxiosClient"; 
import { toast } from "react-toastify"; 
const Subscriptionplans = ()=>{
    const initiate=async()=>{
        try {
            const token = localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await axiosClient.post(
                `/subscriptions/initiate-payment`, null,
                config
            );

            window.location.href = response.data.response.url;
        } catch (error) {
            console.error("Error initiating payment", error);
            toast.error("Error initiating payment");
        }
    }
    return(
        <div>
            <Navbar/>
            <button
            className="text-white rounded-md bg-gradient-to-r from-orange-400 to-yellow-400 px-2 py-2 my-2 ml-2 "
            onClick={initiate}
            >
                Buy Subscription Plan at just Rs 99
            </button>
            <Footer/>
        </div>
    )
}
export default Subscriptionplans;