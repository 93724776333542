import Footer from "../components/Footer"
import Navbar from "../components/Navbar"

const Paymentsuccess=()=>{
    return (
        <>
        <Navbar/>
        <div>Hey this is payment success</div>
        <Footer/>
        </>
    )
}
export default Paymentsuccess