import React from "react";
import { Link } from "react-router-dom";
const Article = (props)=>{
    return(
        <div className="pt-6">
            <Link to={`/all_blogs/${props.link}`} className="flex ">
                <img src={props.img} className=" rounded-lg" style={{height:'18rem'}}></img>
                <div className="ml-10">
                    <div className="font-semibold" style={{fontSize:'1.3rem'}}>{props.title}</div>
                    <div className="mt-3 text-gray-500" style={{fontSize:'1.1rem'}}>{props.bio}</div>
                </div>
            </Link>
        </div>
    )
}
export default Article;