import React, { useState, useEffect } from "react";
import axiosClient from "./AxiosClient";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddToCartButton from "../components/AddToCartBtn"
const Pricing = ({ product, productId, pincode,token }) => {
  const [sortOption, setSortOption] = useState('distance');
  const [vendors, setVendors] = useState([]);
  console.log(vendors)
  useEffect(() => {
    if (pincode) {
      fetchVendorData();
    }
  }, [pincode, sortOption]);

  const handleSortChange = (e) => {
    const selectedSortOption = e.target.value;
    setSortOption(selectedSortOption);
  };

  const fetchVendorData = async () => {
    try {
      const formData = new FormData();
      formData.append("pincode", pincode);

      if (sortOption === 'price_to_high_to_low' || sortOption === 'price_to_low_to_high') {
        formData.append("search", sortOption);
      } else {
        formData.append("search", ''); // For distance option, pass empty string
      }

      const token = localStorage.getItem("token"); // Assuming token is stored in localStorage

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axiosClient.post(
        `/products/get_vendors/${product.name}`,
        formData,
        config
      );

      console.log(`Vendors sorted by ${sortOption}:`, response.data);
      setVendors(response.data.data || []);
    } catch (error) {
      console.error(`Error fetching vendors sorted by ${sortOption}:`, error);
      toast.error("An error occurred while fetching vendors.");
    }
  };

  return (
    <div className="mx-5 my-5">
      <div className="mr-5 w-1/2">
        <div className="flex justify-between px-5 py-5 rounded-lg border border-orange-500 bg-gray-100">
          <div>{pincode}</div>
          <select
            value={sortOption}
            onChange={handleSortChange}
            className="border border-gray-500 rounded-lg px-2 py-1"
          >
            <option value="distance">Distance</option>
            <option value="price_to_high_to_low">Price: High to Low</option>
            <option value="price_to_low_to_high">Price: Low to High</option>
          </select>
        </div>
        <div>
          {vendors.length > 0 ? (
            vendors.map((product) => (
              <div key={product._id} className="border p-3 my-2 bg-yellow-200">
                <div>Medicine Name: {product.name}</div>
                <div>Price: {product.Mrp}</div>
                <div>Pack: {product.Pack}</div>
                <div>Saltcode: {product.Saltcode}</div>
                <AddToCartButton
                  productId={productId}
                  productMainId={product._id}
                  vendorId={product.vendor_id}
                  token={token}
                />
              </div>
            ))
          ) : (
            <div className="border p-3 my-2">
              No vendors available.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
