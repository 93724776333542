import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
const Privacy=()=>{
    return(
        <>
        <Navbar/>
        <div className="mx-20 my-10 py-5">
        <h1 className="font-semibold text-2xl mb-5" style={{textAlign:'center'}}>Privacy Policy</h1>
        <div className="mb-5 text-sm">Before getting into Our Privacy Policy below and what it covers, here are a few things you should know. The SmartPills website (SmartPills.in” or the “Website”) and the SmartPills mobile application (the SmartPills App,” and collectively with the Website, the “Site”) are owned by Saverx Technologies Private Limited, (SmartPills, “We”, “Our”, or “Us”) SmartPills is the sole owner of information collected on this Site. If you use Our Site, you must comply with Our <Link to="/terms" className="text-blue-500 underline">Terms of Use</Link> (“Terms”). If you do not agree to these Terms, please do not use Our Site, products, and/or services and exit the Site immediately. Please know that We are committed to maintaining your privacy. Thank you for entrusting SmartPills with the personal information, including limited personal medical information, that We receive from you (more on this topic below). We understand that this data may be sensitive. Our Privacy Policy describes the personal information collected by or on behalf of SmartPills through the Site and Our interactions with you, and how personal information is used, shared, and protected. We also appreciate you taking the time to look over Our Privacy Policy and hope that We have organized it in a way that makes it easy-to-read. If you do have any questions, please feel free to contact Us at Info@smartpills.in.</div>
        <div className="mb-5 text-sm">Here are the key things We’d like to bring to your attention:</div>
        <ul className="mb-5 text-sm mx-10">
            <li className="mb-2">• SmartPills does not sell or share your personal medical data--information regarding your prescription drugs that are linked to your name, contact information, and other personal data you provide Us.</li>
            <li className="mb-2">• In limited cases, SmartPills may use personal medical data, which is linked to your name and contact information, to fulfill a request that you have made (for example, sending you a prescription discount card and/or coupon, etc.), to perform internal business operations, analytics, and audits with health care providers that will help Us improve Our product and service offerings, and to tell you about programs We believe may be of interest to you</li>
            <li className="mb-2">• Any medical data that We may receive references a prescription that you’ve filled using Our program, including: the drug class, name, dosage, form, and quantity. Unless you’ve given it to Us, We don’t have access to the rest of your medical history or other medical information.</li>
        </ul>
        <ol className="mb-5 text-sm">
            <li className="mb-5 text-sm"><div className="font-semibold">1. Personal Information We Collect</div>
                <ol className="mx-10 my-4">
                    <li className="mb-2"><span className="font-semibold">A. Identifiers:</span> Identifiers such as: a real name, alias, date of birth, postal address, unique personal identifier, online identifier, internet protocol address (“IP Address”), email address, or other similar identifiers.</li>
                    <li className="mb-2"><span className="font-semibold">B. Customer Records:</span> Any of the following types of information: telephone number, information related to your use of Our services or discounts, including information about your prescription medications.</li>
                    <li className="mb-2"><span className="font-semibold">C. Protected Classifications:</span> Characteristics of protected classifications under Indian Government law such as: medical condition and gender.</li>
                    <li className="mb-2"><span className="font-semibold">D. Commercial Information:</span> Commercial information, including records of products or services purchased, obtained, or considered, or other consuming histories or tendencies when using Our services or discounts.</li>
                    <li className="mb-2"><span className="font-semibold">E. Internet Activity:</span> Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer’s interaction with an internet website, application, or advertisement.</li>
                    <li className="mb-2"><span className="font-semibold">F. Geolocation Data:</span> Geolocation data.</li>
                    <li className="mb-2"><span className="font-semibold">G. Inferences:</span> Inferences drawn from any of the information identified in this section to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, preferences, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.This Policy does not apply to any information that is publicly available.</li>
                </ol>
            </li>
            <li><div className="font-semibold mb-4">2. Categories of Sources of Personal Information</div>
                <div className="mb-5 text-sm">We may collect all categories of personal information listed above from the following categories of sources:</div>
                <div className="font-semibold">Source: Information You Provide to Us</div>
                <p className="mb-5 text-sm">We collect personal information that you provide directly to Us.</p>
                <p className="mb-5 text-sm">Generally, We do not require that you provide Us with information or create an account in order to use Our Site or to view Our prices, discounts and services. However, if you request to receive certain services (for example, sending you a prescription discount card and/or coupon, etc.), you may need to provide Us with personal information so that We can fulfill this request. We will tell you what information is required and what information is optional.</p>
                <p className="mb-5 text-sm">By providing your personal information, you agree to let Us use such personal information in order to provide you with the requested service, to perform internal business operations, analytics, and audits with health care providers that will help Us improve Our product and service offerings, and to reach out to you about programs We believe may be of interest to you. Here are some examples where you might provide Us with your personal information:</p>
                <ul className="mb-5 text-sm mx-10">
                    <li className="mb-2">• In order to register for an account, you will need to provide Us (e.g., name, either (i) email address or (ii) phone number, and the password you create).</li>
                    <li className="mb-2">• In order to receive a prescription discount card and/or coupon via email or text, you will need to provide Us your email address or phone number where We can text you. We will use the information provided to tell you about programs We believe may be of interest to you. You may opt-out of receiving text messaging at any time by responding to one of Our texts with the word “STOP.”</li>
                    <li className="mb-2">• If you request a prescription discount card and/or coupon via mail, you must give Us your name and mailing address. We will use the information provided to tell you about programs We believe may be of interest to you.</li>
                    <li className="mb-2">• You automatically sign up for free SmartPills email updates when you request that a SmartPills discount card and/or coupon be sent via email. You have the option to opt out of receiving these updates. This opt-out messaging will appear at the bottom of every email that We send to you and will provide you with the ability to opt out of receiving future emails.</li>
                    <li className="mb-2">• When you call Our customer service team.</li>
                </ul>
                <p className="mb-5 text-sm">Marketing Communications; Opt-Out. If you opt in to receive marketing communications from Us, you must submit your name, email address, mailing address, and, in some cases, phone number. We use this data to send you promotional and other electronic and hardcopy communications. We may use third-parties or service providers to deliver communications to you. You may opt out of such marketing emails by using the unsubscribe link in the email or contacting Us at info@smartpills.in with “Unsubscribe” in the subject line. To opt out of other communications (e.g., postal marketing and telemarketing), please contact Us as set forth below. Opting out of marketing communications does not opt you out of non-marketing communications, such as emails about your account or transactions. Feedback. When you provide comments or feedback about Our Site, the content on the Site, or Our products or services ("Feedback"), We will not treat that Feedback as confidential and We may use that Feedback for any purpose in Our sole discretion so long as it does not personally identify you. Feedback will be used without attribution or compensation to you.</p>
                <p className="mb-5 text-sm">Applying to Work with SmartPills. To submit a job application online to work with SmartPills, you will be directed to a page administered by Our service provider. You will not be required to create an account with that vendor. We encourage you to review that service provider’s privacy policy posted on their website since Our Privacy Policy does not govern your use of their services.If you send Us your job application materials directly, We will only use and share those job application materials to evaluate your qualifications to work with Us and Our affiliates. Business Contracts with Us. When a business contracts directly with Us for the purchase of Our products or services, We may collect the business contact information of those involved. The processing of any information provided in such a business context is governed by the contracts between that business and SmartPills.</p>
                <div className="font-semibold mb-4">Information We May Collect on Our Website or the SmartPills App</div>
                <p className="mb-5 text-sm">You may use the SmartPills services by accessing Our Website or the SmartPills App. When you use Our Website or the SmartPills App, We may collect and use certain technical data and related information, including, without limitation, technical information about your device, system, software, and other ancillary information. This information may be obtained to facilitate the provision of certain services, software updates, and product support. When you use Our Website or the SmartPills App, some or all of the following information may be automatically collected and stored:</p>
                <ul className="mb-5 text-sm mx-4">
                    <li className="mb-2">• Information about how you interact with Our Website https://www.Smartpills.in and the SmartPills App, including how many times you use a specific part of Our Website or the SmartPills App, the amount of time that you spend using Our Website or the SmartPills App, how often you use Our Website or the SmartPills App, and how you engage with Our Website or the SmartPills App;</li>
                    <li className="mb-2">• Your mobile phone number or other unique device identifier assigned to your mobile device, such as the MEID number (sometimes referred to as an Electronic Serial Number);</li>
                    <li className="mb-2">• The IP address of your device;</li>
                    <li className="mb-2">• The manufacturer and model of your mobile device;</li>
                    <li className="mb-2">• Your operating system;</li>
                    <li className="mb-2">• The Internet browser you are using;</li>
                    <li className="mb-2">• Your login information;</li>
                    <li className="mb-2">• Your geolocation; and</li>
                    <li className="mb-2">• Your preferred language.</li>
                </ul>
                <div className="mb-5 text-sm">We may use this information as follows:</div>
                <ul className="mb-5 text-sm mx-4">
                    <li className="mb-2">• To ensure that Our Website or the SmartPills App is operable;</li>
                    <li className="mb-2">• To modify or customize the content on Our Website or the SmartPills App for you;</li>
                    <li className="mb-2">• To improve how We communicate with you through the SmartPills App;</li>
                    <li className="mb-2">• To improve the functionality and engagement of Our Website or the SmartPills App and Our services, generally;</li>
                    <li className="mb-2">• To determine which features of Our Website or the SmartPills App are most useful;</li>
                    <li className="mb-2">• To personalize the services and content available through Our Website or the SmartPills App;</li>
                    <li className="mb-2">• To conduct statistical analysis and extract non-identifiable demographic information;</li>
                    <li className="mb-2">• To identify and investigate violations of the Privacy Policy, Terms of Use, and other unlawful acts.</li>
                </ul>
                <div><div className="font-semibold italic mb-4">Information You Provide in Communications with Us </div>When you contact Us by email or other communications, you may provide Us with your name, contact information and, if you choose to provide it, limited health or medical information. We use this information to respond to you and to try and fulfill your request.</div>
                <div className="font-semibold my-4">Source: We Collect Personal Information Automatically</div>
                <div className="mb-5 text-sm">As you navigate through the Site, certain anonymous information can be passively collected (that is, gathered without you actively providing the information) using various technologies, such as cookies, Internet tags or web beacons, and navigational data collection (log files, server logs, clickstream). Your Internet browser automatically transmits to this Site some of this anonymous information, such as the URL of the website you just came from, the Internet Protocol (IP) address and the browser version your computer or mobile device is currently using, information about how you interact with the Site (e.g., how many times you use a specific part of the Site and amount of time you spend on the Site, how often you use the Site, and how you engage with the Site), your mobile phone number or other unique device identifier assigned to your mobile device, such as the MEID number (sometimes referred to as an Electronic Serial Number), the manufacturer and model of your computer or mobile device, your mobile operating system, your geolocation, and your preferred language.</div>
                <div className="font-semibold">Information from Cookies</div>
                <div className="mb-5 text-sm">If you request a SmartPills discount card and/or coupon, information collected by cookies can be associated with your SmartPills membership account information. (Read more about what information is collected by cookies below.) We use this information to provide you with better, faster service, particularly for recurring visits. There are other places where SmartPills collects data that does not include your name and contact information, including the sections below. We, like many other websites on the internet, collect this data to remember information about you and your preferences. This way, We can provide you, and other individuals like you, with better, faster service, particularly when you return to Our Site.</div>
                <div className="font-semibold">Information from Logs</div>
                <div className="mb-5 text-sm">When you access SmartPills services, Our servers automatically record certain information. These logs include information such as the date and time you access Our Site, the browser you are using, and other common browser information. We will use this log information to help diagnose problems with Our server and to administer Our Site. Your log information also may be used to gather broad demographic information.</div>
                <div className="font-semibold">Information from Location</div>
                <div className="mb-5 text-sm">When you are using SmartPills with a browser or mobile device, you may enter a location or give SmartPills permission to access your location. This enables Us to find you the lowest prescription prices in your area. SmartPills collects and stores that information. We use this data internally; We do not sell or share this data externally with any third parties. We use a variety of technologies that automatically (or passively) collect certain information to determine your location.</div>
                <div className="font-semibold">Information from Embedded Scripts</div>
                <div className="mb-5 text-sm">Client-Side Scripting (embedded scripts) is code that exists inside Our HTML page. This code will be processed on Our machine and the HTML page will NOT perform a Postback to the web server. Generally, Our scripting is used for page navigation, data validation, and formatting.</div>
                <div className="font-semibold">More Information about Cookies</div>
                <div className="mb-5 text-sm">A “cookie” is standard internet technology used to store and track visitor information. It is a bit of information that a website sends to your web browser that helps the website remember information about you and your preferences. You may set your browser to notify you when a cookie is sent or to refuse cookies altogether, but certain features of this Site might not work without cookies. This Site may use and combine such passively collected anonymous information to provide better service to Site visitors, customize the Site based on your preferences, compile and analyze statistics and trends, and otherwise administer and improve the Site for your use. Such information is not combined with personally identifiable information collected elsewhere on the Site unless you have consented. To learn more about cookies and how to specify your preferences, please search for “cookie” in the “Help” portion of your browser.</div>
                <div className="font-semibold">Remarketing</div>
                <div className="mb-5 text-sm">We may use remarketing with Google and other remarketing providers to advertise online. We may place cookies, tags, pixels, SDKs, and other tracking technology on your computer or other devices, or otherwise collect your personal information to assist Google, Facebook, other third parties, or SmartPills to feed advertisements or to send direct mail campaigns to you based on your past visits to SmartPills and other websites; and We may also allow other third parties, including Google, to do so as well. However, We never provide advertisers or any other third parties any information that reveals a personal health condition or personal health or medical information. To opt-out of this type of advertising by Google, to customize your ad preferences, or to limit Google’s collection or use of such data, visit Google’s Safety Center and Google’s Ad Settings and follow Google’s personalized ad opt-out instructions. Opting out will not affect your use of the Site.</div>
                <div className="font-semibold">on SmartPills</div>
                <div className="mb-5 text-sm">We may use third-party advertising companies to serve ads when you visit Our Site. These companies may use information (not including your name, address, email address or telephone number) about your visits to this and other websites in order to provide advertisements about products and services of interest to you. If you would like more information about this practice and to know your choices about not having this information used by these companies. Internet Protocol (IP) Addresses</div>
                <div className="mb-5 text-sm">A website may use Internet Protocol (IP) addresses. An IP address is a number assigned to your computer by your internet service provider so you can access the internet and is generally considered to be non-personally identifiable information, because in most cases an IP address is dynamic (changing each time you connect to the internet), rather than static (unique to a particular user’s computer). The IP address can be used to diagnose problems with a server, report aggregate information, determine the fastest route for your computer to use in connecting to this Site, and administer and improve the Site.</div>
                <div className="font-semibold">Information from “Internet Tags”</div>
                <div className="mb-5 text-sm">“Internet Tags” are also known as single-pixel GIFs, clear GIFs, invisible GIFs, and 1-by-1 GIFs, and are smaller than cookies and tell the Site server information such as the IP address and browser type related to the visitor’s computer. Tags may be placed both on online advertisements that bring people to the Site and on different pages of the Site. Such tags indicate how many times a page is opened and which information is consulted. “Navigational data” (log files, server logs, and clickstream data) are used for system management, to improve the content of the Site, market research purposes, and to communicate information to visitors.</div>
                <div className="font-semibold">Google Analytics Demographics and Interest Information</div>
                <div className="mb-5 text-sm">The Site relies upon data from Google Analytics Demographic and Interest reporting. This feature permits SmartPills to view demographic information as well as other information aggregated from visitors to the Site. This data does not include personally identifying information.</div>
                <div className="mb-5 text-sm">Google sets cookies on your browser or device, and then your web browser will automatically send information to Google. Google uses this information to provide Us with reports that We use to better understand and measure how users interact with Our Site. We may also implement additional add-on services to Google Analytics, such as: Demographics and Interest Reporting, which gathers information such as the age and gender of users, along with certain interests those users express through their purchasing activities and other online activities, by tracking users across websites and across time. Remarketing with Google Analytics, which uses user behaviors on Our Site (and within Our App) to help Us identify users for Our remarketing campaigns.</div>
                <div className="mb-5 text-sm">Google Display Network Impression Reporting, which provides Us information about how often Our ads are shown. To learn more about how Google uses data, visit Google’s Privacy Policy and Google’s page on “How Google uses data when you use Our partners’ sites or apps.” You may download the Google Analytics Opt-out Browser Add-on for each web browser you use, but this does not prevent the use of other analytics tools. To learn more about Google Analytics cookies, visit Google Analytics Cookie Usage on websites.</div>
                <div className="font-semibold">Google Maps</div>
                <div className="mb-5 text-sm">The Site contains maps provided by third parties and/or service providers for your convenience. SmartPills and such other parties may collect data about how you interact with the maps. For example, Google may collect usage data on maps embedded on the Site. Even if you do not interact with the maps, Google may still collect certain information about your interactions with Our Site if you are signed into your Google account when accessing Our Site. Please see Google's Privacy Policy for more information.</div>
                <div className="font-semibold">Video Content</div>
                <div className="mb-5 text-sm">Our Site contains videos and embedded content provided by Us and other parties, including visible content and/or feeds scripts embedded in the Site's code. We and such other parties may collect data about how you interact with such content. For example, YouTube may collect usage data on videos embedded on Our Site. Even if you do not watch videos, YouTube may still collect certain information about your interactions with Our Site if you are signed into your YouTube account or Google account when accessing Our Site. YouTube, LLC is owned by Google. Please see Google's Privacy Policy for more information.</div>
                <div className="mb-5 text-sm">Source: Information We Receive from Third-Party Sources</div>
                <div className="font-semibold">Information You Provide to Our Marketing Partners</div>
                <div className="mb-5 text-sm">Sometimes We offer marketing materials from a third party. You can opt into receiving them by agreeing to provide Us with your name, contact information, and, if you choose to, limited health or medical information. We use this data to fulfill your request for SmartPills materials and as otherwise specified in any third-party marketing campaigns. Data obtained through the short code texting program will not be shared with any third parties for their marketing reasons/purposes.</div>
                <div className="mb-5 text-sm">We encourage you to carefully review the privacy policies and terms of use of any third-party marketing partners, prior to furnishing them with your information. Your interactions outside of the Site will be governed by third-party privacy policies and terms of use. If you receive materials you don’t want from Us, please email Info@smartpills.in and We’ll remove your contact info from Our marketing lists.</div>
                <div className="font-semibold">Information You Provide to Your Health Care Professionals</div>
                <div className="mb-5 text-sm">Some health care professionals who use SmartPills may text or email you a discount card and/or coupon with your permission. In order to receive the discount card and/or coupon from your health care professional, you must furnish them with certain contact information, including your phone number or email address. By providing your health care professional with your phone number or email address, you are requesting to be contacted with prescription-related information, and you acknowledge that there may be some risk that the message could be read by a third party. We use this information to assist in sending you the discount card and/or coupon and to provide you with prescription savings opportunities.</div>
                <div className="font-semibold">Information We Receive from Data Partners</div>
                <div className="mb-5 text-sm">We may supplement the information We collect about you as described above with data We receive from any of Our third-party partners.</div>
                <div className="font-semibold">Information We Receive from Other Third-Party Sources We obtain data about individuals from various third-party companies and public sources and We may combine that data with personal information or other data We collect. This enhances Our existing data about Our users (e.g., adding address data, etc.), improves Our ability to contact you, and enhances Our marketing capabilities.</div>
            </li>
            <li><div className="font-semibold my-4">3. Purpose for Collecting Personal Information</div>
                <div className="mb-5 text-sm">We may use all categories of personal information listed above for the following business purposes:</div>
                <div className="mb-5 text-sm">As Stated or Agreed to at the Point of Collection. We may use personal information for the purposes stated or agreed-to (or as is obvious) at the point of collection. For example, We use personal information to respond to your questions, comments, or complaints. We may also use personal information as requested or consented to by you. Administration We use personal information for administrative purposes, such as managing your account, facilitating transactions, order fulfillment, member support, and other related actions for providing and maintaining Our services, to inform Our business strategies, improving Our products and services, to understand the Site’s demographics and user preferences, for evaluating applications, and managing profiles. Site Management. We use personal information for Site management, such as troubleshooting problems, improving the content and functionality of the Site, statistical and other analyses of the Site, and to customize the Site to you and Our users. For example, on Our Website or mobile application, We use information to identify and repair errors that may occur, to detect security incidents, and to protect against malicious activity. Advertising and Communications. We may use personal information to send you promotions or to perform targeted advertising, to notify you of new services, products or programs, to notify you of new features of Our Site, to notify you of changes to Our Terms or Our Privacy Policy, and for other similar communications. For example, We may send you reminders about refills, or update you with prescription “price drops.</div>
            </li>
            <li><div className="font-semibold mb-4">4. When Do We Share Your Personal Information with A Third Party?</div>
                <div className="mb-5 text-sm">SmartPills rarely shares information with a third party that associates you personally with your specific medical information. When We do disclose your personal information, SmartPills ensures that these third parties are bound to comply with federal standards as to how to handle your personal information. We may share all categories of personal information listed above with the following categories of third parties and/or service providers:
                </div>
                <ul className="mb-5 text-sm mx-4">
                    <li className="mb-2">• We may share personal information with Our employees and affiliates who have a need to know the information for Our business purposes.</li>
                    <li className="mb-2">• We may use one or more service providers to safeguard, protect, store, and/or analyze data. We will ensure that this is done safely and securely.</li>
                    <li className="mb-2">• We may share your name and address with others if you request a discount card and/or coupon to be mailed to the address you provided, and that information may only be shared with the third parties We use to fulfill discount card and/or coupon requests.</li>
                    <li className="mb-2">• We may use other companies to perform services including, without limitation, facilitating some aspects of Our Site, sending email, fulfilling purchase requests, and serving, customizing, or delivering marketing or ads. These other companies may be supplied with or have access to your information solely for the purpose of providing these services to Us or on Our behalf. They do not have any independent right to use or disclose your information. We have contracted with Our service providers to protect any such personal information.</li>
                    <li className="mb-2">• We may share personal information about you to respond to subpoenas, court orders, or other legal process, or to exercise Our legal rights or defend against legal claims.</li>
                    <li className="mb-2">• We will cooperate with law enforcement and other governmental agencies, and may disclose personal information: (i) if We believe in good faith We are legally required to disclose that personal information, (ii) if We are advised to disclose personal information by Our legal counsel, or (iii) when necessary to identify, contact or bring a legal action against someone who may cause or be causing harm to, or interfering with the legal rights of SmartPills or any other party.</li>
                    <li className="mb-2">• We may share personal information with Our professional advisors, such as Our attorneys, accountants, financial advisors, in their capacity as advisors to Us.</li>
                    <li className="mb-2">• If you accept a benefit or offer–or otherwise interact with Our merchant partners–you may be asked to submit personally-identifying information. Our merchant partners may use your personal information in a manner that comports with their privacy policies, and which differs from SmartPills's Privacy Policy.</li>
                    <li className="mb-2">• We may share personal information with third parties and/or service providers when explicitly requested by or consented to by you, or for the purposes for which you disclosed the personal information to Us as indicated at the time and point of the disclosure (or as was obvious at the time and point of disclosure).</li>
                </ul>
                <div className="mb-5 text-sm">SmartPills may transfer its user database, including personal information contained therein, to a third party who acquires all, or substantially all, of the assets or stock of SmartPills whether by merger, acquisition, reorganization, bankruptcy, receivership, or otherwise. We may provide personal information to the subsequent owner(s), including as part of any due diligence process.</div>
            </li>
            <li className=" mb-5"><div className="font-semibold mb-4">5. Use and Disclosure of De-identified or Aggregated Information</div>
                <div className="mb-5 text-sm">We may collect, use, share, transfer and otherwise process de-identified and aggregated information that We receive or create for any purposes in Our sole discretion, in compliance with applicable laws. We are the sole and exclusive owner of such de-identified and aggregated information, including if We de-identify personal information so that it no longer considered personal information under applicable laws.</div>
            </li>
            <div className="mb-5"><div className="font-semibold mb-4">6. How to Control Use of Your Personal Information</div>
                <div className="mb-5 text-sm">SmartPills will retain information We collect from you indefinitely. If you would like to delete the personal information you provided Us or otherwise limit the use of the personal information you provided Us, please contact Us at Info@Smartpills.in. Keep in mind that there are cases where We are required by law to keep a copy of the data, such as to comply with CAN-SPAM (if you request that We do not email you, We need to keep a copy of your email on Our do-not-email list so that We can keep complying with your request).</div>
            </div>
            <li><div className="mb-5 font-semibold">8. Social Media</div>
                <div className="mb-5 text-sm">We are active on social media, including Facebook, YouTube, Twitter, Instagram, and LinkedIn (“Social Media”). Anything you post on Social Media is public information and will not be treated confidentially. We may post (or re-post) on Our Site and Our Social Media pages any comments or content that you post on Our Social Media pages. Our Site allows you to connect and share data with Social Media platforms. These features may require Us to use cookies, plug-ins, and APIs provided by such Social Media platforms to facilitate those communications and features. Our Site may use advertising networks and services offered by Social Media platforms to deliver advertising content. Use of these services requires Social Media platforms to implement cookies or pixel tags to deliver ads to you while you access Our Site. Your use of Social Media is governed by the privacy policies, and terms of the providers that own and operate those websites and not by this Policy. We encourage you to review those policies and terms.</div>
            </li>
            <li><div className="font-semibold">9. Other Things You Should You Know About Your Privacy</div>
                <ul className="mb-5 mx-4 mt-4">
                    <li><div className="font-semibold">• Security</div>
                        <div className="mb-5 text-sm">The security, integrity, and confidentiality of your information are extremely important to Us. We have implemented technical, administrative, and physical security measures that are designed to protect your information from unauthorized access, disclosure, use, and modification. We regularly review Our security procedures to consider commercially appropriate new technology and methods. Please be aware that, despite Our best efforts, no security measures are perfect or impenetrable. Although We will exercise reasonable care in providing secure transmission of information between your computer and Our servers, We cannot ensure or warrant the security of any information transmitted to Us over the Internet and We accept no liability for any unintentional disclosure. You provide personal information at your own risk.If a breach of your personal information occurs, We will notify you of the breach if required under applicable law.</div>
                    </li>
                    <li><div className="font-semibold">• Violation of Law, Rule or Regulation</div>
                        <div className="mb-5 text-sm">The provisions elaborated herein will not apply in the event that you engage in certain improper conduct. SmartPills shall have the right to use or release to third parties personally identifiable information regarding users who use this Site for any unlawful or fraudulent purpose or who engage in conduct which SmartPills deems improper.</div>
                    </li>
                    <li><div className="font-semibold">• Links to Other Websites</div>
                        <div className="mb-5 text-sm">NOTE: We are not responsible for the privacy practices of websites operated by third parties that are linked to or integrated with Our Site, including the websites of Our partners, or for the privacy practices of third-party internet advertising companies. Once you’ve left Our Site via such a link or by clicking on an advertisement, you should check the applicable privacy policy of the third-party or advertiser website to determine how they will handle any information they collect from you. SmartPills does not act on behalf of, nor are We an agency of, any of the pharmacies referenced on this Site, who are independent companies.</div>
                    </li>
                    <li><div className="font-semibold">• Do Not Track Requests</div>
                        <div className="mb-5 text-sm">Currently, We do not respond to browser "Do Not Track" signals</div>
                    </li>
                </ul>
            </li>
            <li><div className="font-semibold mb-4">10. Changes to This Privacy Policy</div>
                <div className="mb-5 text-sm">We may make changes to Our Privacy Policy from time to time. When We do so, We will post the revised Privacy Policy on Our Site. To determine if the Policy has been modified since you last reviewed it, please check the reviewed date at the top of this page.</div>
                <div className="font-semibold mb-4">We Do Not Sell Personal Information</div>
                <div className="mb-5 text-sm">We have not sold your personal information and We currently do not sell personal information.</div>
            </li>
            <li><div className="font-semibold mb-4">11. Contacting SmartPills</div>
                <div className="mb-5 text-sm">Please feel free to connect us on info@smartpills.in</div>
            </li>
        </ol>
        </div>
        <Footer/>
        </>
    )
}
export default Privacy;