import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoginPopup from './LoginPopup';
import noteContext from '../context/notes/noteContext';

const TokenExpiryChecker = ({ children }) => {
  const { expiredToken, checkTokenExpiry } = useContext(noteContext);
  const location = useLocation();

  useEffect(() => {
    checkTokenExpiry();
  }, [checkTokenExpiry]);

  if (location.pathname === '/') return null; // Don't show the popup on the home page for the first-time visit
  if (['/signin', '/signup', '/loginotp', "/otp"].includes(location.pathname)) return null; // Don't show the popup on these specific pages

  return expiredToken ? <LoginPopup /> : <>{children}</>; // Show popup if token is expired
};

export default TokenExpiryChecker;
