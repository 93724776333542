import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from '../components/AxiosClient';

const Prescriptions = () => {
  const [prescriptions, setPrescriptions] = useState([]);

  useEffect(() => {
    const fetchPrescriptions = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error('Token not found');
        return;
      }

      try {
        const response = await axiosClient.post('/users/prescriptions', null, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

        if (response.ok) {
          const data = await response.json();
          console.log('Prescriptions:', data);
          setPrescriptions(data);
        } else {
          const errorBody = await response.text();
          console.error('Failed to fetch prescriptions:', errorBody);
          toast.error('Failed to fetch prescriptions');
        }
      } catch (error) {
        console.error('Error fetching prescriptions:', error);
        toast.error('Error fetching prescriptions');
      }
    };

    fetchPrescriptions();
  }, []);

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-4">Prescriptions</h1>
        {prescriptions.length > 0 ? (
          <div>
            {/* Render prescriptions */}
            {prescriptions.map((prescription, index) => (
              <div key={index} className="mb-4">
                <p>{prescription}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>You don't have any uploaded prescriptions yet.</p>
        )}
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default Prescriptions;
